<script setup lang="ts">
    import * as basicScroll from 'basicscroll';
    import type { BackgroundColorsPredefined, LookbookCollageData, LookbookContainerData, LookbookHighlightData } from '~/@types/cms';
    import type { IWidgetProps } from '~/composables/widgets';

    const props = defineProps<IWidgetProps<LookbookContainerData>>();
    const { data } = useWidget(props);

    const bgStyle = useStyle('bg');
    const fromStyle = useStyle('from');

    const colorMapperBg = bgStyle.colorMapper;
    const colorMapperFrom = fromStyle.colorMapper;

    const background = computed(() => {
        const colorKey = (data.style?.background?.background_color ?? 'light') as BackgroundColorsPredefined;
        return colorMapperBg[colorKey];
    });

    const backgroundFrom = computed(() => {
        const colorKey = (data.style?.background?.background_color ?? 'light') as BackgroundColorsPredefined;
        return colorMapperFrom[colorKey];
    });

    const isTop = computed(() => {
        return data?.style?.settings?.includes('is_top');
    });

    const lookbookContainer = ref<Element | null>(null);

    const scrollCreateAndStart = ({ elem, from = 'top-bottom', to = 'bottom-top', props, direct = true }: Partial<basicScroll.Data>) => {
        if (!elem) return;

        const created = basicScroll.create({
            elem,
            from,
            to,
            direct,
            props,
        });
        created.start();
    };

    onMounted(async () => {
        // TODO: Find better way to handle than wait
        await wait(3000);
        if (data?.style?.settings?.[0] === 'is_top') {
            scrollCreateAndStart({
                elem: lookbookContainer.value?.querySelector(`#${data.codename} .lb-mouse-icon`),
                from: 'middle-middle',
                to: 'middle-top',
                direct: true,
                props: {
                    '--opacity': {
                        from: 0.99,
                        to: 0.01,
                    },
                    '--scale': {
                        from: 1,
                        to: 0,
                    },
                    '--translate': {
                        from: 0,
                        to: '200px',
                    },
                },
            });
        }
        scrollCreateAndStart({
            elem: lookbookContainer.value?.querySelector(`#${data.codename} .lb-hero-image`),
            from: 'top-top',
            direct: true,
            props: {
                '--translateY': {
                    from: '0%',
                    to: '50%',
                },
            },
        });
        if (data?.hero?.logo) {
            scrollCreateAndStart({
                elem: lookbookContainer.value?.querySelector(`#${data.codename} .lb-hero-content`),
                from: 'top-middle',
                direct: true,
                props: {
                    '--translate-logo': {
                        from: '0',
                        to: '75%',
                    },
                },
            });
        }
        data?.lb_h_content?.components.forEach((content) => {
            const baseSelector = `#${data.codename} #${content.codename}`;
            if (content.types.includes('LookbookHighlightWidget')) {
                scrollCreateAndStart({
                    elem: lookbookContainer.value?.querySelector(`${baseSelector} .lb-highlight-visual`),
                    direct: true,
                    props: {
                        '--translate': {
                            from: '15vh',
                            to: '0',
                        },
                    },
                });
                scrollCreateAndStart({
                    elem: lookbookContainer.value?.querySelector(`${baseSelector} .lb-highlight-content`),
                    to: 'bottom-middle',
                    direct: true,
                    props: {
                        '--translate': {
                            from: '-5vh',
                            to: '5vh',
                        },
                    },
                });
            }
            if (content.types.includes('LookbookCollageWidget')) {
                const cast = content as LookbookCollageData;
                const assetElement1 = lookbookContainer.value?.querySelector(`${baseSelector} .lb-collage-asset-1`);
                if (assetElement1) {
                    scrollCreateAndStart({
                        elem: assetElement1,
                        to: 'middle-middle',
                        direct: true,
                        props: {
                            '--translate': {
                                from: '-25vh',
                                to: '0',
                            },
                        },
                    });
                }
                const assetElement2 = lookbookContainer.value?.querySelector(`${baseSelector} .lb-collage-asset-2`);
                if (assetElement2) {
                    scrollCreateAndStart({
                        elem: assetElement2,
                        to: 'middle-middle',
                        direct: true,
                        props: {
                            '--translate': {
                                from: '-30vh',
                                to: '0',
                            },
                        },
                    });
                }
                const assetElement3 = lookbookContainer.value?.querySelector(`${baseSelector} .lb-collage-asset-3`);
                if (assetElement3) {
                    if (cast.style?.render === 'type1') {
                        scrollCreateAndStart({
                            elem: assetElement3,
                            to: 'middle-middle',
                            direct: true,
                            props: {
                                '--translate': {
                                    from: '35vh',
                                    to: '0',
                                },
                            },
                        });
                    } else {
                        scrollCreateAndStart({
                            elem: assetElement3,
                            to: 'middle-top',
                            direct: true,
                            props: {
                                '--translate': {
                                    from: '50vh',
                                    to: '0',
                                },
                            },
                        });
                    }
                }
            }
            if (content.types.includes('LookbookSlideInWidget')) {
                const cast = content as LookbookHighlightData;
                const direction = cast.style?.settings?.includes('flip') ? '100%' : '-100%';
                scrollCreateAndStart({
                    elem: lookbookContainer.value?.querySelector(`${baseSelector} .lb-slide-visual`),
                    to: 'top-top',
                    direct: true,
                    props: {
                        '--translate': {
                            from: direction,
                            to: '0%',
                        },
                    },
                });
            }
            if (content.types.includes('LookbookFooterWidget')) {
                scrollCreateAndStart({
                    elem: lookbookContainer.value?.querySelector(`${baseSelector} .lb-footer-image`),
                    to: 'top-middle',
                    direct: true,
                    props: {
                        '--translate': {
                            from: '50vh',
                            to: '0',
                        },
                    },
                });
            }
        });
    });
</script>
<template>
    <div
        :id="data.codename"
        ref="lookbookContainer"
        :class="[background, { 'text-woom-white': data.style?.background?.background_color === 'black' }]">
        <mol-hero
            v-if="data.hero"
            :hero-data="{
                codename: `hero-${data.hero.codename}`,
                id: data.hero.id,
                language: data.language,
                types: [],
                hero: data.hero.background,
                logo: data.hero.logo,
                style: {
                    halign: 'center',
                    valign: 'middle',
                },
            }"
            :is-top="isTop"
            :is-first-hero="componentIndex === 0"
            full-height />
        <div :class="[backgroundFrom, 'relative -mt-52 flex h-52 items-center justify-center bg-gradient-to-t to-transparent']">
            <!-- TODO: move svg to icons library -->
            <svg
                v-if="isTop"
                width="22"
                height="32"
                viewBox="0 0 22 32"
                :class="[
                    'lb-mouse-icon',
                    {
                        'text-woom-white': ['black', 'mossgreen'].includes(data?.style?.background?.background_color || ''),
                    },
                ]">
                <path
                    d="M11 0c6.065 0 11 4.935 11 11v10c0 6.065-4.935 11-11 11S0 27.065 0 21V11C0 4.935 4.935 0 11 0zm0 2c-4.963 0-9 4.037-9 9v10c0 4.963 4.037 9 9 9s9-4.037 9-9V11c0-4.963-4.037-9-9-9zm0 4c1.655 0 3 1.345 3 3v4c0 1.655-1.345 3-3 3s-3-1.345-3-3V9c0-1.655 1.345-3 3-3zm0 2a1 1 0 0 0-1 1v4a1 1 0 0 0 2 0V9a1 1 0 0 0-1-1z"
                    fill="currentColor"
                    fill-rule="nonzero" />
            </svg>
        </div>
        <cms-content-controller
            v-if="data.lb_h_content"
            :class="[background, 'relative lg:pt-20']"
            :data="data.lb_h_content" />
    </div>
</template>

<style scoped>
    .lb-mouse-icon {
        opacity: var(--opacity);
        transform: scale(var(--scale)) translateY(var(--translate));
        will-change: opacity, transform;
    }
</style>
